import { IConfigsType, IRouteType } from '../types'
import acePop, { routes as acePopRoutes } from './ace_pop'
import bibibop, { routes as bibibopRoutes } from './bibibop'
import burgerworks, { routes as burgerworksRoutes } from './burgerworks'
import drg, { routes as drgRoutes } from './drg'
import fwWingstop, { routes as fwWingstopRoutes } from './fw_wingstop'
import ghaiPop, { routes as ghaiPopRoutes } from './ghai_pop'
import irmgCc, { irmgBkRoutes, irmgCcRoutes } from './irmg_cc'
import jscBk, { routes as jscRoutes } from './jsc_bk'
import lfr, { routes as lfrRoutes } from './lfr'
import lfrEf, { routes as lfrEfRoutes } from './lfr_ef'
import mwb, { routes as mwbRoutes } from './mwb'
import primanti, { routes as primantiRoutes } from './primanti'
import timoney, { routes as timoneyBkRoutes } from './timoney_bk'
import wingitnorth, { routes as wingitnorthRoutes } from './wingitnorth'

export const routes: Record<string, IRouteType[]> = {
  lfr: lfrRoutes,
  lfr_ef: lfrEfRoutes,
  lfr_tr: lfrEfRoutes,
  lfr_sn: lfrEfRoutes,
  lfr_lv: lfrEfRoutes,
  lfr_vb: lfrEfRoutes,
  lfr_tu: lfrEfRoutes,
  lfr_ws: lfrEfRoutes,
  lfr_jn: lfrEfRoutes,
  lfr_mm: lfrEfRoutes,
  lfr_dt: lfrEfRoutes,
  lfr_uv: lfrEfRoutes,
  lfr_ol: lfrEfRoutes,
  jsc_bk: jscRoutes,
  irmg_bk: irmgBkRoutes,
  irmg_cc: irmgCcRoutes,
  primanti: primantiRoutes,
  ghai_pop: ghaiPopRoutes,
  bibibop: bibibopRoutes,
  sullivans: drgRoutes,
  sullivans_nso: drgRoutes,
  eddiemerlots: drgRoutes,
  eddiemerlots_nso: drgRoutes,
  mwb: mwbRoutes,
  burgerworks: burgerworksRoutes,
  wingitnorth: wingitnorthRoutes,
  fw_wingstop: fwWingstopRoutes,
  ace_pop: acePopRoutes,
  timoney_bk: timoneyBkRoutes,
}

const getLfrConfigs = (brand: string) =>
  lfrEf.map((configs) => ({
    [brand]: configs['lfr_ef'],
  }))

const getDrgConfigs = (brand: string) =>
  drg.map((configs) => ({
    [brand]: configs['drg'],
  }))

const getIrmgConfigs = (brand: string) =>
  irmgCc.map((configs) => ({
    [`${brand}-plaid_page_b61d68b9`]: configs['irmg_cc'],
  }))

export default [
  ...lfr,
  ...lfrEf,
  ...getLfrConfigs('lfr_tr'),
  ...getLfrConfigs('lfr_sn'),
  ...getLfrConfigs('lfr_lv'),
  ...getLfrConfigs('lfr_vb'),
  ...getLfrConfigs('lfr_tu'),
  ...getLfrConfigs('lfr_ws'),
  ...getLfrConfigs('lfr_jn'),
  ...getLfrConfigs('lfr_mm'),
  ...getLfrConfigs('lfr_dt'),
  ...getLfrConfigs('lfr_uv'),
  ...getLfrConfigs('lfr_ol'),
  ...jscBk,
  ...getIrmgConfigs('irmg_bk'),
  ...getIrmgConfigs('irmg_cc'),
  ...primanti,
  ...ghaiPop,
  ...bibibop,
  ...getDrgConfigs('sullivans'),
  ...getDrgConfigs('sullivans_nso'),
  ...getDrgConfigs('eddiemerlots'),
  ...getDrgConfigs('eddiemerlots_nso'),
  ...mwb,
  ...burgerworks,
  ...wingitnorth,
  ...fwWingstop,
  ...acePop,
  ...timoney,
].reduce(
  (result, configs) =>
    Object.keys(configs).reduce(
      (subResult, key) => ({
        ...subResult,
        [key]: {
          ...subResult[key],
          ...configs[key],
        },
      }),
      result,
    ),
  {} as IConfigsType,
)
